<template>
  <div class="has-text-centered ">
    <treeselect v-model="selection"
                :multiple="false"
                :show-count="true"
                @input="onSelect"
                :options="categories"/>
  </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import store from "../../store/deplo.store"
var flatten = require('flat')

export default {
  name: "CategoryEditComponent",
  components: { Treeselect },
  props: ["rowIndex", "model", "close", "save"],
  data() {
    return {
      selection: this.model.category.id,
    }
  },
  computed: {
    categories() {
      return store.state.categories
    },
  },
  methods: {
    flatten(data, root, accumulator){
      return data.reduce((acc, val) => {
        accumulator.push({
          id: val.id,
          name: val.name,
          path: root + val.name
        });
        if (val.children)
          return this.flatten(val.children, root + val.name + "/", accumulator);
        else
          return accumulator;
      }, accumulator);
    },
    onSelect(e) {
      console.log(e)
      let result = store.state.categories.find(e => {
        console.log(e, this.selection)
        return e.id === this.selection
      })
      console.log(result)
      this.save(this.selection)
    }
  }

}
</script>

<style scoped>

</style>