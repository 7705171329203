<template>
  <section class="section is-main-section pb-0">
    <title-bar :title-stack="titleStack"/>


    <card-component icon="history" title="Placed Mapitems" class="has-table has-mobile-sort-spaced">
      <card-toolbar slot="toolbar" class="is-upper">
        <b-field grouped slot="left">
          <b-button @click="$router.push({ name: 'mapitems.overview', params: {project_id: id}});" icon="mapbox">Back to map</b-button>
        </b-field>

        <b-field grouped slot="right">
          <b-button @click="download">Download</b-button>
        </b-field>
      </card-toolbar>
        <v-grid style="height: calc(100vh - 170px)"
                ref="grid"
                :autoSizeColumn="true"
                :source="mapitems"
                :columns="columns"
                :editors="gridEditors"
                :resize="true"
                :range="true"
                :exporting="true"
                :rowHeaders="{
                  size: 40
                }"
                theme="material"

                @afteredit="update"
        />
    </card-component>

  </section>
</template>
<script>
import VGrid, {VGridVueEditor} from '@revolist/vue-datagrid';
import SelectTypePlugin from "@revolist/revogrid-column-select";
import TitleBar from "@/components/TitleBar";
import CardComponent from '@/components/CardComponent'
import deplo from "../store/deplo.store";
import StatusEditComponent from "@/components/deplo/components/editors/StatusEditComponent";
import Vue from "vue";
import CategoryEditComponent from "@/components/deplo/components/editors/CategoryEditComponent";
import CardToolbar from "@/components/CardToolbar";

const status_editor = VGridVueEditor(Vue.component("statusEdit", StatusEditComponent));
const category_editor = VGridVueEditor(Vue.component("categoryEdit", CategoryEditComponent));

export default {
  name: "ItemTableComponent",
  components: { CardToolbar, VGrid, TitleBar, CardComponent },
  data(){
    return {
      plugin: { 'select': new SelectTypePlugin() },
      gridEditors: { status: status_editor, category: category_editor },
      columns: [
        { prop: 'name', name: 'Name', size: 350, autoSize: true, pin: 'colPinStart',
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              style: {
                paddingLeft: props.model["depth"] + "em"
              },
            }, props.model[props.prop]);
          },
        },
        { prop: 'category',
          name: 'Category',
          size: 200,
          editor: "category",
          cellTemplate: (createElement, props) => {
            return createElement('span', {
            }, props.model[props.prop].name);
          },
        },
        { prop: 'level', name: 'Level', size: 70, },
        { prop: 'location', name: 'Location', size: 200, autoSize: true },
        {
          prop: 'status',
          name: 'Status',
          editor: "status",
          cellTemplate: (createElement, props) => {
            return createElement('span', {
              class: `tag ${props.model[props.prop].color}`,
            }, props.model[props.prop].name);
          },
        },

      ],

    }
  },
  created() {
    if (!deplo.isRegistered) {
      this.$store.registerModule('deplo', deplo)
      deplo.isRegistered = true
    }
  },
  beforeMount() {
    this.$store.dispatch("deplo/fetchAllMapItems", this.id)
    this.$store.dispatch("deplo/fetchAllCategories")
  },
  computed: {
    titleStack () {
      return [
        'Project',
        'Categories',
        'Categories'

      ]
    },
    id() {
      return this.$route.params.project_id
    },
    mapitems() {
      return this.$store.state.deplo.mapitems
    }
  },
  methods: {
    update(e) {
      console.log(e)

      let models = []
      if(e.detail.models) {
        models = e.detail.models
      }
      if(e.detail.model) {
        models.push(e.detail.model)
      }

      Object.entries(models).forEach(model => {
        console.log(model)
        let formData = { id: model[1].id }
        if (e.detail.data) {
          // Multiple selected
          console.log(e.detail.data[model[0]])
          Object.assign(formData, e.detail.data[model[0]])
        }
        else {
          // One selected
          formData[e.detail.prop] = e.detail.val
        }
        console.log("FORMDATA: ", formData)

        if(formData['status']) {
          formData['status_id'] = formData['status'].id
        }
        // TODO Temporary Hack
        if(formData['category'] && e.detail.data) {
          formData['category_id'] = formData['category'].id
        }
        if(formData['category'] && !e.detail.data) {
          formData['category_id'] = formData['category']
        }

        console.log(formData)
        this.$store.dispatch("deplo/updateMapItem", formData).then(res => {
          this.$buefy.toast.open({
            message: 'MapItem updated',
            type: 'is-success',
            queue: false
          })
        })
      })
    },

    download() {
      const grid = document.querySelector('revo-grid');
      grid.getPlugins().then(plugins => {
        plugins.forEach(p => {
          if (p.exportFile) {
            const exportPlugin = p;
            exportPlugin.exportFile({  filename: 'new file' });
          }
        })
      });
    }
  }
}
</script>

<style scoped>


</style>