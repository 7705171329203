<template>
  <div class="has-text-centered pt-2">
    <b-select size="is-small" v-model="selection" @input="onSelect">
      <option :value="1">Draft</option>
      <option :value="2">Quoted</option>
      <option :value="3">Planned</option>
      <option :value="4">Placed</option>
      <option :value="5">Tested</option>
      <option :value="6">Error</option>
    </b-select>
  </div>
</template>

<script>
export default {
  name: "StatusEditComponent",
  props: ["rowIndex", "model", "close", "save"],
  data() {
    return {
      selection: this.model.status.id,
      // TODO Options uit store halen
      options: {
        1: {name: 'Draft', id: 1, color: 'is-grey'},
        2: {name: 'Quoted', id: 2, color: 'is-warning'},
        3: {name: 'Planned', id: 3, color: 'is-info'},
        4: {name: 'Placed', id: 4, color: 'is-purple'},
        5: {name: 'Tested', id: 5, color: 'is-success'},
        6: {name: 'Error', id: 6, color: 'is-danger'}
      }
    }
  },
  methods: {
    onSelect() {
      this.save(this.options[this.selection])
    }
  }

}
</script>

<style scoped>

</style>